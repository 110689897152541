<!-- 动态表单上传 -->
<template>
  <div class="box">
    <div class="title" v-if="state === '00'">
      <!-- 添加 -->
      <div style="margin-left: 17px">
        <el-button
          :class="[listData.length === 3 ? 'disable' : '']"
          @click="addClick"
          size="mini"
          type="primary"
          icon="el-icon-plus"
          >{{ btnTitle }}
        </el-button>
      </div>
    </div>
    <div style="display: flex">
      <div class="list_box" v-for="(item, index) in listData" :key="index">
        <!-- 上传 -->
        <div v-if="state === '00'">
          <div class="upload_img" @click="uploadImg(index)" v-if="!item.url">
            <i class="el-icon-plus"></i>
          </div>
          <img
            v-else
            @click="uploadImg(index)"
            style="
              width: 100px;
              height: 100px;
              border: 1px solid #c1cbff;
              cursor: pointer;
            "
            :src="item.url"
          />
        </div>
        <div v-else>
          <el-image
            style="width: 60px; height: 60px; border: 1px solid #c1cbff"
            :src="item.url"
            :preview-src-list="[item.url]"
          >
          </el-image>
        </div>
        <div
          class="delete"
          v-if="index"
          v-show="state === '00'"
          @click="listData.splice(index, 1)"
        >
          <i class="el-icon-circle-close" />
        </div>
      </div>
    </div>
    <DialogAction
      :modal="modal"
      v-bind="uploadProps"
      @close="
        uploadProps.show = false;
        uploadProps[uploadProps.index].url = '';
      "
      @config="uploadConfig"
    >
      <template v-slot:from>
        <div class="inner">
          <div class="tab">
            <el-tooltip
              class="item"
              effect="dark"
              content="粘贴上传"
              placement="top-start"
            >
              <div
                @click="uploadProps.currentTab = 'copy'"
                :class="uploadProps.currentTab === 'copy' ? 'active_tab' : ''"
              >
                <img
                  v-if="uploadProps.currentTab === 'copy'"
                  src="https://img.fanxinghuishou.cn/20230912/043746741.png"
                  alt=""
                />
                <img
                  v-else
                  src="https://img.fanxinghuishou.cn/20230912/777867251.png"
                  alt=""
                />
              </div>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="选择本地图片"
              placement="top-start"
            >
              <div
                @click="uploadProps.currentTab = 'upload'"
                :class="uploadProps.currentTab === 'upload' ? 'active_tab' : ''"
              >
                <img
                  v-if="uploadProps.currentTab === 'upload'"
                  src="https://img.fanxinghuishou.cn/20230912/814145275.png"
                  alt=""
                />
                <img
                  v-else
                  src="https://img.fanxinghuishou.cn/20230912/254382542.png"
                  alt=""
                />
              </div>
            </el-tooltip>
          </div>
          <div
            class="content"
            v-loading="uploadProps.loading"
            :style="{
              border: `${
                uploadProps.imageUrl ? '1px dashed #4C84FF' : '0px #fff'
              }`,
            }"
          >
            <div
              class="content_copy content_style"
              v-if="uploadProps.currentTab === 'copy'"
            >
              <p @paste="handlePaste">先点击，再Ctrl+V粘贴图片</p>
            </div>
            <div
              class="content_upload content_style"
              v-if="uploadProps.currentTab === 'upload'"
            >
              <p><i class="el-icon-plus"></i>上传图片</p>
              <input
                @input="fileChange"
                type="file"
                name="file"
                accept="image/*"
              />
            </div>
          </div>
        </div>
        <div
          class="copy_inner"
          v-loading="uploadProps.loading"
          element-loading-text="上传中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.3)"
          v-show="uploadProps.imageUrl"
        >
          <img
            class="copy_img"
            v-loading="uploadProps.loading"
            id="copyImg"
            :src="uploadProps.imageUrl"
            alt=""
          />
          <div class="mask">
            <i class="el-icon-view" @click="preview"></i>
            <i class="el-icon-delete" @click="deleteImg"></i>
          </div>
        </div>
      </template>
    </DialogAction>
  </div>
</template>
<script>
import DialogAction from "@/components/common/DialogAction.vue";
import axios from "axios";
import environments from "@/config/url";
import { encryptByDES } from "@/utils/3DES";

export default {
  name: "JudgementsList",
  components: { DialogAction },
  data() {
    return {
      uploadProps: {
        show: false,
        title: "上传图片",
        headerMessage: "",
        isMessage: false,
        isTip: false,
        width: "600px",
        currentTab: "copy",
        imageUrl: "",
        loading: false,
        url: "",
      },
      // 内部更改
      listData: [],
      // 避免进入死循环，只有每次列更新才调事件回显
      isWatch: true,
    };
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    // 外部传入初始值
    sourceData: {},
    // 标题
    title: {
      type: String,
      default: "判责依据",
    },
    // 按钮文字
    btnTitle: {
      type: String,
      default: "添加图片",
    },
    // 左方标题
    leftTitle: {
      type: String,
      default: "依据",
    },
    // 最大长度
    maxLength: {
      type: Number,
      default: 3,
    },
    // 图片限制
    fileSize: {
      type: Number,
      default: 5,
    },
    //是否重置
    isReset: {
      type: Boolean,
      default: false,
    },
    //赋值
    assignmentData: {
      type: Array,
      default: () => [],
    },
    // 当前状态
    state: {
      type: String,
      default: "00",
    },
  },
  // dataChange数据变化时，通知父组件的事件
  watch: {
    // 传递值给父组件
    listData: {
      handler(v) {
        if (this.isWatch) {
          this.$emit("dataChange", v);
        } else {
          // 此时的任何数据更改，除非又是外部传入，反之，统一设置为true
          this.isWatch = true;
        }
      },
      deep: true,
    },
    // 清楚值
    isReset(v) {
      if (v) {
        this.listData = [
          {
            url: "",
            reason: "",
            judgeType: "",
          },
        ];
      }
    },
    // 赋值操作
    assignmentData: {
      handler(v) {
        // 外部直接赋值，不能执行listData监听，否则造成死循环
        // 使用深层监听，导致未跟新其值，会根据指针执行，所以禁止深层监听
        this.isWatch = false;
        if (v.length) {
          this.listData = v;
        } else {
          this.listData = [
            {
              url: "",
              reason: "",
              judgeType: "",
            },
          ];
        }
      },
      // deep: true,
    },
  },
  created() {
    this.listData = this.sourceData;
    if (this.sourceData.length) {
      return (this.listData = this.sourceData);
    }
    return (this.listData = [
      {
        url: "",
        reason: "",
        judgeType: "",
      },
    ]);
  },
  methods: {
    uploadImg(index) {
      this.uploadProps = {
        ...this.uploadProps,
        show: true,
        imageUrl: this.listData[index].url,
        loading: false,
        url: this.listData[index].url,
        index,
        currentTab: this.listData[index].currentTab
          ? this.listData[index].currentTab
          : "copy",
      };
    },
    fileChange(e) {
      console.log(e.target.files);
      const isLIMIT = e.target.files[0].size / 1024 / 1024 < this.fileSize;
      if (!isLIMIT) {
        return this.$message.error(`上传图片大小不能超过 ${this.fileSize}MB!`);
      }
      if (this.uploadProps.loading) {
        return this.$message.error(`有图片正在上传，请稍等!`);
      }
      const result = e.target.files[0];
      this.request(result);
      let URL = window.URL || window.webkitURL;
      let imgURL = URL.createObjectURL(result);
      this.uploadProps.loading = true;
      this.uploadProps.imageUrl = imgURL;
    },
    uploadConfig() {
      const { index, url, imageUrl } = this.uploadProps;
      this.listData[index].url = url;
      this.listData[index].currentTab = this.uploadProps.uploadCurrentTab;
      this.uploadProps.show = false;
    },

    request(blob) {
      const formDataBody = new FormData();
      formDataBody.append("file", blob);
      axios
        .post(
          environments[process.env.VUE_APP_BASE_URL].uploadBaseUrl,
          formDataBody,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              channel: "platformPc",
              ACCESS_TOKEN: localStorage.getItem("token"),
              TOKEN: encryptByDES(
                encryptByDES(
                  new Date().getTime().toString(),
                  "63e42c2444e94c1ebca21d30d2aa39a5"
                ),
                localStorage.getItem("token") || ""
              ),
            },
          }
        )
        .then((r) => {
          this.$message.success("上传成功");
          const data = r.data ?? {};
          if (data.code === 1) {
            this.uploadProps.url = data.orgUrl;
            this.uploadProps.imageUrl = data.orgUrl;
            this.uploadProps.uploadCurrentTab = this.uploadProps.currentTab;
          } else {
            this.uploadProps.url = "";
            this.uploadProps.loading = false;
            this.$message.error("请务上传过大的图片，且只支持JPG或PNG格式");
            this.uploadProps.imageUrl = "";
          }
          this.uploadProps.loading = false;
        })
        .catch(() => {
          this.uploadProps.url = "";
          this.uploadProps.loading = false;
          this.$message.error("请务上传过大的图片，且只支持JPG或PNG格式");
          this.uploadProps.imageUrl = "";
        });
    },
    getCopyContent() {
      navigator.clipboard.read().then((clipboardItems) => {
        clipboardItems.forEach((item) => {
          if (
            item.types.includes("image/png") ||
            item.types.includes("image/jpeg")
          ) {
            item.getType("image/png").then((blob) => {
              // 将图像数据显示在页面上
              const reader = new FileReader();
              reader.onload = (event) => {
                this.uploadProps.imageUrl = event.target.result;
                const img = document.getElementById("copyImg");
                img.src = this.uploadProps.imageUrl;
              };
              reader.readAsDataURL(blob);
              this.uploadProps.loading = true;
              this.request(blob);
            });
          } else {
            this.$message.error("只支持粘贴图片");
          }
        });
      });
    },
    getCopyContent1(event) {
      event.preventDefault();
      // 获取粘贴事件的 clipboardData
      const clipboardData = event.clipboardData || window.clipboardData;
      let hasImage = false;
      if (clipboardData && clipboardData.items) {
        for (const item of clipboardData.items) {
          if (item.type.indexOf("image") !== -1) {
            // 这是一张图片
            console.log(item.getAsFile());
            const isLIMITs =
              item.getAsFile().size / 1024 / 1024 < this.fileSize;
            if (!isLIMITs) {
              return this.$message.error(
                `上传图片大小不能超过 ${this.fileSize}MB!`
              );
            }
            if (this.uploadProps.loading) {
              return this.$message.error(`有图片正在上传，请稍等!`);
            }
            const blob = item.getAsFile();
            this.uploadProps.imageUrl = URL.createObjectURL(blob);
            const img = document.getElementById("copyImg");
            img.src = this.uploadProps.imageUrl;
            this.uploadProps.loading = true;
            this.request(blob);
            hasImage = true;
          }
        }
        if (!hasImage) {
          this.$message.error("只支持粘贴图片");
        }
      }

      // if (event.clipboardData || event.originalEvent) {
      //   const clipboardData = (event.clipboardData || event.originalEvent.clipboardData);
      //   if (clipboardData.items) {
      //     let blob;
      //     for (let i = 0; i < clipboardData.items.length; i++) {
      //       if (clipboardData.items[i].type.indexOf("image") !== -1) {
      //         blob = clipboardData.items[i].getAsFile();
      //       }else {
      //         return this.$message.error("只支持粘贴图片");
      //       }
      //     }
      //     // 通过 FileReader 将 file 转为 base64
      //     const render = new FileReader();
      //     render.onload = function (ev) {
      //       //输出base64编码
      //       const base64 = ev.target.result;
      //       this.uploadProps.imageUrl = base64;
      //       const img = document.getElementById('copyImg');
      //       img.src = this.uploadProps.imageUrl;
      //     }
      //     render.readAsDataURL(blob);
      //     this.uploadProps.loading = true;
      //     this.request(blob);
      //   }
      // }
    },
    handlePaste(e) {
      console.log(e);

      // this.getCopyContent();
      this.getCopyContent1(e);
    },
    addClick() {
      if (this.listData.length === this.maxLength) return;
      this.listData.push({
        url: "",
        reason: "",
        judgeType: "",
      });
    },
    preview() {
      this.$viewerApi({
        images: [this.uploadProps.imageUrl],
        options: {
          zIndex: 99999999999,
        },
      });
    },
    deleteImg() {
      this.uploadProps.imageUrl = "";
      this.uploadProps.url = "";
    },
  },
};
</script>
<style scoped lang="scss">
.box {
  .title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }

  .list_box {
    position: relative;
    padding-left: 15px;
    margin-top: 16px;
    display: flex;
    align-items: center;

    /deep/ .el-upload {
      width: 60px;
      height: 60px;
      border: 1px dashed #c1cbff;
      transform: translateY(4px);
    }

    > p {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      margin-right: 17px;
    }

    > textarea {
      width: 500px;
      height: 60px;
      background: #f9fbfd;
      border: 1px solid #c1d1ff;
      border-radius: 4px;
      padding: 12px;
      margin-left: 16px;
      margin-right: 13px;
      box-sizing: border-box;

      &:focus {
        outline: none;
      }
    }

    > .delete {
      cursor: pointer;
      color: #fd677d;
      font-size: 24px;
      position: absolute;
      top: -18px;
      right: -10px;
    }
  }
}

.disable {
  border-color: #bebebe !important;
  background: #bebebe !important;
  cursor: text !important;
}

.disable_result {
  border-color: #eee !important;
  background: #eee !important;
  cursor: no-drop !important;
}

.upload_img {
  width: 100px;
  height: 100px;
  font-size: 19px;
  text-align: center;
  line-height: 100px;
  color: #bfbfbf;
  cursor: pointer;
  border: 1px solid #dde5f6;
  background: #eff1f4;
  border-radius: 4px;
}

.inner {
  width: 100%;
  border: 1px solid #dde5f6;
}

.tab {
  padding: 10px 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  border: 1px solid #dde5f6;

  > div {
    cursor: pointer;
    padding: 0 26px;
    margin-right: 10px;
    height: 100%;
    position: relative;

    &::after {
      content: "";
      width: 1px;
      height: 21px;
      background: #e4ecfd;
      position: absolute;
      right: 0;
      top: 2px;
    }

    > img {
      transform: translateY(4px);
      padding-bottom: 10px;
      box-sizing: content-box;
    }
  }

  .active_tab img {
    width: 18px;
    height: 18px;
    color: #444cf1;
    border-bottom: 1px solid #4c84ff;
  }
}

.content {
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 100px;
  width: calc(100% - 20px);
  box-sizing: border-box;
  border: 1px solid #0981ff;
}

.content_style {
  text-align: center;
  width: 100%;
  color: #4c84ff;
}

.content_style {
  font-size: 14px;
  color: #666;
  line-height: 100px;
  cursor: pointer;

  p {
    color: #4c84ff;
    font-size: 14px;
  }
}

.copy_img {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.content_upload {
  position: relative;
  cursor: pointer;
}

.content_upload input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.copy_inner {
  margin-left: 10px;
  margin-bottom: 10px;
  width: 160px;
  height: 160px;
  position: relative;
  border: 1px solid #dde5f6;
  background: #eff1f4;
  border-radius: 4px;
  margin-top: 21px;
  cursor: pointer;

  .mask {
    display: none;
  }
}

.copy_inner:hover .mask {
  width: 160px;
  height: 160px;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;

  > i {
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
</style>
